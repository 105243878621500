import { gooseClient } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import { ComputeStatus } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    getTreeItemById,
    selectionListProjectTree,
    getObjectTree,
    loadedObjectById,
    loadedObjectsMap,
} from 'src/store/visualization/selectors';
import {
    addToLoadedObjects,
    removeFromLoadedObjects,
} from 'src/store/visualization/visualizationSlice';
import { ROOT_TREE_ID } from 'src/strings';
import { getViewIdFromObjectId } from 'src/visualization/context/snapshots/generateSnapshot';
import {
    ERROR_MESSAGE,
    OBJECT_TOO_LARGE,
} from 'src/visualization/ObjectsPanel/ProjectTree/ObjectListItemControl/ObjectListItemControl.constants';

/**
 * You only need to supply the listItemId if you want to listen for the object to be loaded, in most cases you will want this
 * @param listItemId
 * @returns
 */
export function useObjectManager(listItemId: string) {
    const dispatch = useAppDispatch();
    const selectedObjectIds = useAppSelector(selectionListProjectTree);
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const treeItem = useAppSelector(getTreeItemById(listItemId));

    function loadObject() {
        if (!treeItem?.schema) return;
        if (isObjectLoaded(listItemId)) return;
        setError('');
        dispatch(addToLoadedObjects(treeItem));
    }
    function reloadObject() {
        if (!treeItem?.schema) return;
        setError('');
        // First, we remove the object from the loaded objects so that it can be reloaded
        dispatch(removeFromLoadedObjects(listItemId));

        // Then, we dispose the entity to remove it from the plot
        const viewId = getViewIdFromObjectId(listItemId, treeItem.schema);
        disposeEntity(viewId);

        // Finally, we add the object back to the loaded objects
        // It needs to be inside a setTimeout because Redux won't unmount the ListItem
        // component if we dispatch this action synchronously
        setTimeout(() => dispatch(addToLoadedObjects(treeItem)));
    }

    const { treeState } = useAppSelector(getObjectTree);
    function loadSelectedObjects() {
        selectedObjectIds.forEach((treeId) => {
            const selectedTreeItem = treeState[treeId];
            if (selectedTreeItem) {
                dispatch(addToLoadedObjects(selectedTreeItem));
            }
        });
    }

    const loadedObjects = useAppSelector(loadedObjectsMap);
    function isObjectLoaded(objectId: string) {
        return objectId in loadedObjects;
    }

    const { useXyzListener, addViewStatusListener, disposeEntity } = useBaseXyz();
    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    const queryObjectId =
        treeItem && treeItem.parentId !== ROOT_TREE_ID ? treeItem.parentId : listItemId;

    const selectQuery = useMemo(
        () =>
            gooseClient.endpoints.getObjectById.select({
                orgId,
                workspaceId,
                objectId: queryObjectId,
            }),
        [],
    );

    const { isError: isNetworkError } = useAppSelector(selectQuery);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const loadedObject = useAppSelector(loadedObjectById(listItemId));
    const loadedObjectExists = Boolean(loadedObject);

    useEffect(() => {
        if (isObjectLoaded(listItemId)) {
            setIsLoading(true);
        }
    }, [loadedObjectExists]);

    useEffect(() => {
        if (isNetworkError) {
            setError(ERROR_MESSAGE);
        }
    }, [isNetworkError]);

    useEffect(() => {
        const objectViewId = views.find((view) => view.startsWith(listItemId));
        if (!objectViewId) {
            setError('');
            if (!loadedObject) {
                setIsLoading(false);
            }
            return () => {};
        }

        return addViewStatusListener({
            viewId: objectViewId,
            onComplete: () => {
                setIsLoading(false);
                setError('');
            },
            onError: (failedKey: string) => {
                trackError(`Error retrieving status on ${failedKey}`);
                setIsLoading(false);
                if (failedKey === ComputeStatus.FailedTooBig) {
                    setError(OBJECT_TOO_LARGE);
                } else {
                    setError(ERROR_MESSAGE);
                }
            },
            onPending: () => {
                setIsLoading(true);
                setError('');
            },
        });
    }, [views]);

    return {
        loadObject,
        loadSelectedObjects,
        reloadObject,
        error,
        isLoading,
        isObjectLoaded,
    };
}
